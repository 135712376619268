/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Container from "../../components/container"
import Header from "../../components/header"
import Layout from "../../layouts"
import Title from "../../components/hero/title"

import PageMetadata from "../../components/page-metadata"
import { getFeaturesData } from "../../utils/get-csv-features-data"
import LogoDictionary from "../../components/features/logo-dictionary"
import LegendTable from "../../components/features/legend-table"
import EvaluationTable from "../../components/features/evaluation-table"
import PageWithSidebar from "../../components/page-with-sidebar"
import Breadcrumb from "../../components/docs/breadcrumb"

const FeatureComparison = ({
  pageContext,
  data: { allGatsbyCmsSpecsCsv, allGatsbyJamstackSpecsCsv },
  location,
}) => {
  const { options, featureType } = pageContext
  const optionsDisplay = options.map(o => o.display)
  const titleString = `Comparison of Gatsby vs ${optionsDisplay.join(` vs `)}`

  const { sections, sectionHeaders } =
    featureType === `cms`
      ? getFeaturesData(allGatsbyCmsSpecsCsv.nodes)
      : getFeaturesData(allGatsbyJamstackSpecsCsv.nodes)

  return (
    <Layout pathname={location.pathname}>
      <PageMetadata title={titleString} />
      <Header isFullWidth="true" isFixed />
      <PageWithSidebar location={location}>
        <main
          id={`reach-skip-nav`}
          sx={t => ({
            pb: 0,
            [t.mediaQueries.desktop]: {
              pt: 10,
            },
          })}
        >
          <Container customStyles={{ position: `static` }}>
            <Breadcrumb location={location} />
            <Title>{titleString}</Title>
            {options.map(o => (
              <section key={o.key} sx={{ mb: 7 }}>
                <h2
                  sx={{
                    display: `flex`,
                    alignItems: `center`,
                  }}
                >
                  <img
                    src={LogoDictionary[o.key]}
                    alt={o.display}
                    sx={{
                      height: `25px`,
                      marginBottom: 0,
                      marginRight: `10px`,
                    }}
                  />
                  {o.display}
                </h2>
                {o.description}
              </section>
            ))}
            <LegendTable />
            <EvaluationTable
              options={options}
              sections={sections}
              sectionHeaders={sectionHeaders}
            />
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default FeatureComparison

export const pageQuery = graphql`
  query FeatureComparisonQuery {
    allGatsbyCmsSpecsCsv {
      nodes {
        Category
        Subcategory
        Feature
        Gatsby
        WordPress
        Drupal
        Description
      }
    }
    allGatsbyJamstackSpecsCsv {
      nodes {
        Category
        Subcategory
        Feature
        Gatsby
        Nextjs
        Jekyll
        Hugo
        Nuxtjs
        Description
      }
    }
  }
`
